import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { lightTheme } from '../theme';
import GlobalStyles from '../GlobalStyles';
import Navbar from '../components/Navbar';

import Footer from '../components/Footer';
import Helmet from '../components/Layout';
import TomtitHeroJoin from '../components/TomtitHeroJoin';
import TomtitFeatures from '../components/TomtitFeatures';
import TomtitAutomation from '../components/TomtitAutomation';

const Tomtit = () => {
  useEffect(() => {
    if (typeof window === 'object') {
      return window.scrollTo(0, 0);
    }
  }, []);
  return (
    <ThemeProvider theme={lightTheme}>
      <Helmet />
      <GlobalStyles />
      <>
        <Navbar />
        <TomtitHeroJoin />
        <TomtitFeatures />
        <TomtitAutomation />
        <Footer />
      </>
    </ThemeProvider>
  );
};

export default Tomtit;
