import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import AnimateHeight from 'react-animate-height';

const INITIAL_HEIGHT = 90;

const CardItem = ({ descriptionKey, titleKey }) => {
  const [height, setHeight] = useState(INITIAL_HEIGHT);

  const handleClick = () => {
    if (height === INITIAL_HEIGHT) {
      return setHeight('auto');
    }

    return setHeight(INITIAL_HEIGHT);
  };

  return (
    <CardItemWrapper onClick={handleClick}>
      <AnimateHeight duration={500} height={height}>
        <Title>
          <FormattedMessage id={titleKey} />
        </Title>
        <Description>
          <FormattedMessage id={descriptionKey} />
        </Description>
      </AnimateHeight>
      {height === INITIAL_HEIGHT && (
        <Label>
          <FormattedMessage id="more_info" /> →
        </Label>
      )}
    </CardItemWrapper>
  );
};

export default CardItem;

const CardItemWrapper = styled.div`
  padding: 24px;
  border-radius: 8px;
  box-shadow: 2px 2px 8px 8px rgba(10, 16, 34, 0.05), 0 0 0 transparent;
  cursor: pointer;
  position: relative;
  z-index: 3;
  background: white;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 16px;
`;

const Description = styled.div`
  color: #8698a4;
`;

const Label = styled.div`
  color: #48a1f8;
`;
