import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';

import { Section, Button } from './common';
import breakpoints from '../utils/breakpoints';

import gamepadIcon from '../assets/analysis.svg';
import workIcon from '../assets/test.svg';
import contractIcon from '../assets/drag.svg';

import WorkBenefitItem from './WorkBenefitItem';
import { RoundBox } from './RoundBox';
import safeScrollTo from '../utils/safeScrollTo';

const disabledButtonStyle = {
  cursor: 'no-drop',
  background: '#fcaf4a7d',
};

const TestLabHeroJoin = () => (
  <StyledSection>
    <HeaderWrapper>
      <FirstRoundBox />
      <SecondRoundBox />
      <ContineroSubtitle>
        <FormattedMessage id="Tomtit_Hero_title" />
      </ContineroSubtitle>
      <ContineroTitle>
        <FormattedMessage id="Tomtit_Hero_title2" />
      </ContineroTitle>
      <TitleDescription>
        <FormattedMessage id="Tomtit_Hero_subtitle" />
      </TitleDescription>
      <div>
        <Button onClick={() => openInNewTab('https://tomtit.app')}>
          <FormattedMessage id="Tomtit_Hero_button" />
        </Button>
      </div>
    </HeaderWrapper>
    <WorkBenefitWrapper>
      <WorkBenefitItem
        isColumn
        icon={contractIcon}
        titleKey="Tomtit_Feature1_title"
        descriptionKey="Tomtit_Feature1_description"
      />

      <WorkBenefitItem
        isColumn
        icon={workIcon}
        titleKey="Tomtit_Feature2_title"
        descriptionKey="Tomtit_Feature2_description"
      />

      <WorkBenefitItem
        isColumn
        icon={gamepadIcon}
        titleKey="Tomtit_Feature3_title"
        descriptionKey="Tomtit_Feature3_description"
      />
    </WorkBenefitWrapper>

    
  </StyledSection>
);

export default TestLabHeroJoin;

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const StyledSection = styled(Section)`
  position: relative;
`;

const FirstRoundBox = styled(RoundBox)`
  width: 475px;
  height: 482px;
  top: -300px;
  left: -220px;

  background: linear-gradient(
    134.58deg,
    #fff6df 0.22%,
    rgba(255, 250, 237, 0.4) 99.78%
  );
  border-radius: 90px;
`;

const SecondRoundBox = styled(RoundBox)`
  width: 500px;
  height: 291px;
  top: 100px;
  right: -900px;

  /* Yellow gradient-pattern */

  background: linear-gradient(
    140.82deg,
    #fff6df 0.22%,
    rgba(255, 250, 237, 0.4) 99.78%
  );
  border-radius: 40px;
`;

const ThirdRoundBox = styled(RoundBox)`
  width: 304px;
  height: 365px;

  background: linear-gradient(
    129.79deg,
    #fff6df 0.22%,
    rgba(255, 250, 237, 0.4) 99.78%
  );
  border-radius: 70px;
  left: -400px;
  bottom: 70px;
  width: 500px;
  height: 304px;
`;

const WeLookForWrapper = styled.div`
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
  margin-top: 64px;
`;

const WeLookForText = styled.div`
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #8698a4;
  margin: 32px 0;

  position: relative;
  z-index: 2;
`;

const HeaderWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  position: relative;
`;

const WorkBenefitWrapper = styled.div`
  margin-top: 64px;

  ${breakpoints.tablet} {
    display: flex;

    & > div {
      width: 33.333%;
    }
  }
`;

const ContineroTitle = styled.div`
  font-weight: bold;
  font-size: 64px;
  line-height: 60px;

  position: relative;
  z-index: 3;

  ${breakpoints.mobile} {
    font-size: 100px;
    line-height: 100px;
  }
`;

const ContineroSubtitle = styled.div`
  position: relative;
  z-index: 3;
  font-size: 32px;
  ${breakpoints.mobile} {
    font-size: 48px;
  }
`;

const TitleDescription = styled.div`
  position: relative;
  z-index: 3;
  font-size: 18px;
  line-height: 24px;
  margin: 16px 0;

  ${breakpoints.mobile} {
    margin: 32px 0;
    font-size: 24px;
    line-height: 36px;
  }
`;
